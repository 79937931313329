import { baseAxios, errorHandler } from "../index";
import Swal from "sweetalert2";
import jsPDF from "jspdf";
import "jspdf-autotable";
import moment from "moment";

export async function getAllPengajuan(dispatch, data, history) {
  try {
    const response = await baseAxios.get(
      `webPengajuan/getAllPengajuan?page=${data.page}&limit=${data.limit}&cari=${data.cari}&statusPengajuan=${data.statusPengajuan}&tglAwal=${data.tglAwal}&tglAkhir=${data.tglAkhir}`,
      {
        headers: { token: localStorage.getItem("token") },
      }
    );

    dispatch({
      type: "SET_GETALL_PENGAJUAN",
      data: response.data,
    });
    // console.log(response, "ALL");

    return response.status;
  } catch (err) {
    return err.response;
  }
}

export async function getPengajuanNonCair(dispatch, data, history) {
  try {
    const response = await baseAxios.get(
      `webPengajuan/getAllPengajuanNonCair?page=${data.page}&limit=${data.limit}&cari=${data.cari}&statusPengajuan=${data.statusPengajuan}`,
      {
        headers: { token: localStorage.getItem("token") },
      }
    );

    dispatch({
      type: "SET_GETALL_PENGAJUAN_NON_CAIR",
      data: response.data.data,
    });
    dispatch({
      type: "SET_NON_CAIR_ESTIMASI",
      data: response.data.estimasi,
    });
    // console.log(response, "NON CAIR");
    return response;
  } catch (err) {
    return err.response;
  }
}

export async function updatePengajuan(data) {
  // console.log(data, "TTTT");
  try {
    const response = await baseAxios.patch("webPengajuan/pengajuan", data, {
      headers: { token: localStorage.getItem("token") },
    });
    return response.data;
  } catch (err) {
    return err.response;
  }
}

export async function deletePengajuan(data) {
  try {
    const response = await baseAxios.post(
      "webPengajuan/deletePengajuan",
      data,
      {
        headers: { token: localStorage.getItem("token") },
      }
    );
    return response.data;
  } catch (err) {
    return err.response;
  }
}

export async function getAllAngsuran(dispatch, data, history) {
  try {
    const response = await baseAxios.get(
      `webAngsuran/getAngsuran?idPengajuan=${data.idPengajuan}`,
      {
        headers: { token: localStorage.getItem("token") },
      }
    );
    dispatch({ type: "SET_GET_ALL_ANGSURAN", data: response.data });
    return response.status;
  } catch (err) {
    return err.response;
  }
}

export async function updatePencairan(data) {
  // console.log(data, "HHH");
  try {
    const response = await baseAxios.post(
      "webPengajuan/updatePencairan",
      data,
      {
        headers: { token: localStorage.getItem("token") },
      }
    );
    return response;
  } catch (err) {
    return err.response;
  }
}

export async function exportPencairan(data) {
  try {
    Swal.fire({
      title: "Loading...",
      allowOutsideClick: false,
      showConfirmButton: false,
      onBeforeOpen: () => {
        Swal.showLoading();
      },
    });

    const response = await baseAxios.post(
      "webPengajuan/exportPencairan",
      data,
      {
        headers: { token: localStorage.getItem("token") },
      }
    );
    Swal.close();

    if (response.data.length === 0) {
      Swal.fire({
        icon: "warning",
        title: "Maaf",
        text: "Tidak ada pencairan pada tanggal ini.",
      });
      return [];
    }
    let total = 0;
    response.data.map((val) => {
      total += val.nominalTerima;
    });
    const pdf = new jsPDF({
      orientation: "landscape",
      unit: "mm",
      format: [215.9, 330.2],
    });
    pdf.setFontSize(28);
    pdf.setFont("bold");
    pdf.text("List Pencairan Pugar", 105, 13);
    pdf.line(12, 20, 285, 20);
    pdf.setFontSize(14);
    const tanggalPencairan = moment(response.data[0].tglPencairan).format(
      "dddd, DD MMMM YYYY"
    );
    const tanggalDownload = moment(new Date()).format("dddd, DD MMMM YYYY");
    pdf.text("Tgl Download :", 201, 30);
    pdf.text(tanggalDownload, 233, 30);

    pdf.text("Tgl Pencairan :", 14, 30);
    pdf.text(tanggalPencairan, 46, 30);

    pdf.text("Total Pencairan :", 14, 40);
    pdf.text(formatCurrency(total), 48, 40);

    const headers = [
      "No",
      "ID Pengajuan",
      "Nama",
      "Nopeg",
      // "Tanggal Pencairan",
      "Nominal Pengajuan",
      "Nominal Pencairan",
      "Nominal Blokir",
      "Tenor (Bulan)",
      "Tipe",
      "Bank",
      "No Rekening",
    ];

    let increment = 1;

    const rows = response.data.map((item) => [
      increment++,
      item.idPengajuan,
      item.user.nama,
      item.user.nopeg,
      // formatDate(item.tglPencairan),
      formatCurrency(item.plafond),
      formatCurrency(item.nominalTerima),
      formatCurrency(item.nominalBlokir),
      `${item.tenor}`,
      item.typePengajuan === "new" ? "New" : "Top Up",
      item.user.bank,
      item.user.noRekening,
    ]);

    pdf.autoTable({
      startY: 45,
      head: [headers],
      body: rows,
      theme: "grid",
      styles: {
        lineColor: [0, 0, 0],
        lineWidth: 0.2,
      },
      headerStyles: {
        fillColor: [100, 61, 180],
        textColor: [255, 255, 255],
        fontSize: 12,
        lineColor: [100, 61, 180],
      },
      columnWidth: [15, 30, 30, 20, 40, 40, 25, 20, 25, 25, 35],
    });

    let finalY = pdf.lastAutoTable.finalY;
    if (finalY + 60 > pdf.internal.pageSize.height) {
      pdf.addPage(); // Add a new page
      finalY = 40; // Reset vertical position for the new page
    }
    pdf.setFontSize(10);

    pdf.text("HRD", 40, finalY + 30);
    pdf.text("(...................................)", 27, finalY + 60);
    pdf.text("Payroll", 238, finalY + 30);
    pdf.text("(...................................)", 227, finalY + 60);
    pdf.output("dataurlnewwindow");

    pdf.save(`Pencairan Pugar ${moment().format("DD-MMMM-YYYY")}.pdf`);
    return response.data;
  } catch (err) {
    Swal.fire({
      icon: "error",
      title: "Oops...",
      text: "Terjadi kesalahan saat mengambil data pencairan.",
    });
    return [];
  }
}

function formatCurrency(amount) {
  return new Intl.NumberFormat("id-ID", {
    style: "currency",
    currency: "IDR",
    minimumFractionDigits: 0,
  }).format(amount);
}

function formatDate(date) {
  return moment(date).format("DD-MM-YYYY");
}

export async function getFormPengajuan(data) {
  try {
    const response = await baseAxios.post("webPdf/formPengajuan", data, {
      headers: { token: localStorage.getItem("token") },
    });

    // console.log(response.data, "LLL");
    return response.data;
  } catch (err) {
    console.error("Error fetching PDF:", err);
    throw err;
  }
}

export async function exportPencairanExcel(data) {
  try {
    const response = await baseAxios.post(
      "webPengajuan/exportPencairanExcel",
      data,
      {
        headers: { token: localStorage.getItem("token") },
        responseType: "blob",
      }
    );
    // console.log(response, "AAA");
    return response.data;
  } catch (err) {
    return err.response;
  }
}

export async function exportAllPengajuanExsist(data) {
  try {
    const response = await baseAxios.post(
      "webPengajuan/exportAllPengajuan",
      data,
      {
        headers: { token: localStorage.getItem("token") },
        responseType: "blob",
      }
    );
    // console.log(response, "AAA");
    return response.data;
  } catch (err) {
    return err.response;
  }
}

export async function exportAllPengajuanLunas(data) {
  try {
    const response = await baseAxios.post(
      "webPengajuan/exportAllPengajuanLunas",
      data,
      {
        headers: { token: localStorage.getItem("token") },
        responseType: "blob",
      }
    );
    // console.log(response, "AAA");
    return response.data;
  } catch (err) {
    return err.response;
  }
}

// export async function exportPencairanExcelNonCair(data) {
//   try {
//     const response = await baseAxios.get(
//       `webPengajuan/getAllPengajuanNonCairExport?cari=""&statusPengajuan="",`,
//       {
//         headers: { token: localStorage.getItem("token") },
//         responseType: "blob",
//       }
//     );
//     // console.log(response, "AAA");
//     return response.data;
//   } catch (err) {
//     return err.response;
//   }
// }
