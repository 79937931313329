import axios from "axios";
import { applyMiddleware, combineReducers, createStore } from "redux";
import { composeWithDevTools } from "redux-devtools-extension";
import { reduxSoldierMiddleware } from "redux-soldier";

// reducer
import authReducer from "./auth";
import dashboardReducer from "./dashboardutama";
import userReducer from "./user";
import dataProjectReducer from "./project";
import dataPartnerReducer from "./perusahaan";
import dashboardUtamaReducer from "./dashboardutama";
import strukturalReducer from "./struktural";
import simpananReducer from "./simpanan";
import pengajuanReducer from "./pengajuan";
import angsuranReducer from "./angsuran";
import saldoReducer from "./saldo";
import notifReducer from "./notifikasi";
import pelunasanReducer from "./pelunasan";

// function
export * from "./auth/function";
export * from "./user/function";
export * from "./project/function";
export * from "./perusahaan/function";
export * from "./errorHandler";
export * from "./dashboardutama/function";
export * from "./struktural/function";
export * from "./simpanan/function";
export * from "./pengajuan/function";
export * from "./angsuran/function";
export * from "./saldo/function";
export * from "./notifikasi/function";
export * from "./pelunasan/function";

// server api
// export const baseUrl = process.env.REACT_APP_URL
export const baseUrl = "https://apipugar.ptbap.net/";
// export const baseUrl = "http://192.168.100.123:9050/";
// export const baseUrl = "http://10.5.50.181:9050/";
// export const baseUrl = "https://t5w2szrw-9030.asse.devtunnels.ms/";
// export const baseUrl = "http://192.168.110.50:9020/";
// export const baseUrl = "http://192.168.100.40:9090/";
// export const baseUrl = "http://192.168.110.160:9030/";
// export const baseUrl = "http://localhost:9050/";

export const baseAxios = axios.create({
  baseURL: baseUrl,
  // timeout: 10000,
});

const rootReducer = combineReducers({
  authReducer,
  dashboardReducer,
  userReducer,
  dataProjectReducer,
  dataPartnerReducer,
  dashboardUtamaReducer,
  strukturalReducer,
  simpananReducer,
  pengajuanReducer,
  angsuranReducer,
  saldoReducer,
  notifReducer,
  pelunasanReducer,
});

export const store = createStore(
  rootReducer,
  composeWithDevTools(applyMiddleware(reduxSoldierMiddleware))
);
